































































/* eslint-disable @typescript-eslint/camelcase */

import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import { Component, Vue } from 'vue-property-decorator'
import InputNumber from '@/components/Controls/InputNumber.vue'
import Calendar from '@/components/Controls/Calendar.vue'
import { componentsImports } from '../FormFields/index'

@Component({
  components: {
    ...componentsImports(),
    ValidationProvider,
    InputNumber,
    Calendar
  }
})
export default class CommercialStrataOfficeSuite extends Vue {
  formData () {
    return {
      GeneralCondition: this.generalCondition,
      ApproximateBuildYear: this.approximateBuildYear,
      FloorArea: this.floorArea,
      NumberOfProfessionalOffices: this.numberOfProfessionalOffices,
      CarParks: this.carParks,
      FloorConstruction: this.floorConstruction,
      WallConstruction: this.wallConstruction,
      RoofConstruction: this.roofConstruction,
      AnnualNetRental: this.annualNetRental,
      AnnualGrossRental: this.annualGrossRental,
      AnnualOutgoings: this.annualOutgoings,
      AccommodationFeatures: this.accommodationFeatures,
      PropertyFeatures: this.propertyFeatures,
      EstimatedValue: this.estimatedValue
    }
  }

  loadData (data: any) {
    this.generalCondition = data.GeneralCondition
    this.approximateBuildYear = data.ApproximateBuildYear
    this.floorArea = data.FloorArea
    this.numberOfProfessionalOffices = data.NumberOfProfessionalOffices
    this.carParks = data.CarParks
    this.floorConstruction = data.FloorConstruction
    this.wallConstruction = data.WallConstruction
    this.roofConstruction = data.RoofConstruction
    this.annualNetRental = data.AnnualNetRental
    this.annualGrossRental = data.AnnualGrossRental
    this.annualOutgoings = data.AnnualOutgoings
    this.accommodationFeatures = data.AccommodationFeatures
    this.propertyFeatures = data.PropertyFeatures
    this.estimatedValue = data.EstimatedValue
  }

  propertyType () {
    return 'Commercial'
  }

  generalCondition = null
  approximateBuildYear = null
  floorArea = null
  numberOfProfessionalOffices = null
  carParks = null
  floorConstruction = null
  wallConstruction = null
  roofConstruction = null
  annualNetRental = null
  annualGrossRental = null
  annualOutgoings = null
  estimatedValue = null
  accommodationFeatures: string[] = []
  propertyFeatures: string[] = []
}
